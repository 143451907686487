$(function() {
	//
	// Global
	//
	let controller = new ScrollMagic.Controller();
	const $root = $('html, body');

	//
	// Header
	//
	new ScrollMagic.Scene({
		triggerElement: "body",
		triggerHook: 0,
		offset: 1
	})
		.addTo(controller)
		.on("enter", function () {
			$(".rain-ffp-header").addClass("rain-ffp-header-sticky");
		})
		.on("leave", function () {
			$(".rain-ffp-header").removeClass("rain-ffp-header-sticky");
		});


	//Parallax Copy
	function parallaxCopy() {
		[].slice.apply(document.querySelectorAll(".parallax-copy")).forEach(function (el) {
			let opts = {triggerElement: el, triggerHook: "onEnter", duration: "125%"},
				scene = new ScrollMagic.Scene(opts).setTween(el, {x: "-25%", ease: Linear.easeNone}).addTo(controller);
		});
	}

	//Parallax rotated items
	[].slice.apply(document.querySelectorAll(".parallax-rotated")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "150%"},
			scene = new ScrollMagic.Scene(opts).setTween(el, {y: "-50%", ease: Linear.easeNone}).addTo(controller);
	});

	//Parallax background
	[].slice.apply(document.querySelectorAll(".parallax-parent")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: "onEnter", duration: "175%"},
			scene = new ScrollMagic.Scene(opts).setTween($(el).find(".parallax-parent-img"), {
				y: "80%",
				ease: Linear.easeNone
			}).addTo(controller);

		$(el).fadeIn();
	});

	//fade up
	[].slice.apply(document.querySelectorAll(".fade-up")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	[].slice.apply(document.querySelectorAll(".fade-up-children")).forEach(function (el) {
		let opts = {triggerElement: el, triggerHook: 1, offset: 20},
			scene = new ScrollMagic.Scene(opts).addTo(controller);
		scene.on("start end", function (event) {
			el.classList.add("in-view");
		});
	});

	// Smooth scrolling for anchor link
	function smoothScroll() {
		$(document).on("click", 'a[href^="#"]', function () {
			if ($.attr(this, 'href').length > 1) {
				$root.animate({
					scrollTop: $($.attr(this, 'href')).offset().top - 64
				}, 300);

				hideNavMenu();
			}

			return false;
		});
	}

	smoothScroll();

	//For fixed nav background
	$(window).scroll(function () {
		setStickyHeader();
	});

	setStickyHeader();

	function setStickyHeader() {
		if ($(document).scrollTop() > 0) {
			$(".rain-vip-header").addClass("active");
		} else {
			$(".rain-vip-header").removeClass("active");
		}
	}

	//Hamburger menu click event
	$("#js-hamburger").click(function (e) {
		e.preventDefault();
		$(this).toggleClass("active");
		$(".rain-ffp-header").toggleClass("active");
	});

	//Hide Menu
	function hideNavMenu() {
		$(".rain-ffp-header-hamburger, .rain-ffp-header").removeClass("active");
	}

	//
	//Contact Us
	//

	//Selectmenu for normal form
	$("#js-contact-us-selectmenu").selectmenu({
		classes: {
			"ui-selectmenu-button": "placeholder"
		},
		change: function (event, ui) {
			let _thisVal = $(this).val();

			if (_thisVal === "Advertiser seeking agency partnership") {
				thankYouMessage("1");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-1", ".rain-contact-step-2-2, .rain-contact-step-2-3");
			} else if (_thisVal === "Media vendor seeking partnership") {
				thankYouMessage("2");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-2", ".rain-contact-step-2-1, .rain-contact-step-2-3");
			} else if (_thisVal === "Other") {
				thankYouMessage("3");
				showNormalFormSelectMenuOptions(".rain-contact-step-2-3", ".rain-contact-step-2-1, .rain-contact-step-2-2");
			}

			$("#js-contact-us-selectmenu-button").removeClass("placeholder");
			$(".rain-contact .rain-checkbox input[type=checkbox]").prop( "checked", false );
		}
	});

	function thankYouMessage(_id) {
		let _thankYouCopy = $(".rain-contact-step-6").find("p");

		if (_id === "1") {
			_thankYouCopy.html("Someone from Rain the Growth Agency will contact you shortly.");
		} else if (_id === "2") {
			_thankYouCopy.html("We’ll keep your information on file and will reach out if there’s an opportunity to work together.");
		} else if (_id === "3") {
			_thankYouCopy.html("We’ll follow up with you about your question or comment.");
		}
	}

	//Selectmenu hide/show section for normal form
	function showNormalFormSelectMenuOptions(_show, _hide) {
		$(_hide).slideUp(function () {
			$(_show).slideDown();
		});
	}

	let _currentStep;
	let _nextStep;

	$(".js-contact-next").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rain-contact-step")
		_nextStep = _this.attr("data-step")

		contactFormValidation();
	});

	$(".js-contact-prev").click(function (e) {
		e.preventDefault();
		let _this = $(this);
		_currentStep = _this.closest(".rain-contact-step")
		_nextStep = _this.attr("data-step");
		showDesiredStep();
	});

	//Validation
	function contactFormValidation() {
		let _form = $("#rain-contact-form-normal");

		let servicesRule = {
			required: {
				depends: function(element) {
					return $('.rain-contact-step-2-1-checkbox:checked').length === 0;
				}
			},
		};

		let mediaRule = {
			required: {
				depends: function(element) {
					return $('.rain-contact-step-2-2-checkbox:checked').length === 0;
				}
			},
		};

		$(".rain-contact-step-2-1-checkbox, .rain-contact-step-2-2-checkbox").change(function() {
			_form.valid();
		});

		_form.validate({
			errorPlacement: function (label, element){
				if (element.is(":checkbox") || element.is(":radio")) {
					// console.log(element[0].name);
					// we only want to show 1 for the checkboxes but we have an error trying to show for each checkbox
					if (element[0].name !== "services2" &&
						element[0].name !== "services3" &&
						element[0].name !== "services4" &&
						element[0].name !== "media2" &&
						element[0].name !== "media3" &&
						element[0].name !== "media4" &&
						element[0].name !== "media5" &&
						element[0].name !== "media6" &&
						element[0].name !== "media7") {
						let _container = $(element).closest(".rain-contact-step-checkbox-container");
						_container.addClass("error");
						label.appendTo(_container);
					}
				} else if (element.is("select")) {
					$(element).parent().addClass("error");
					label.insertAfter(element.parent().find(".ui-selectmenu-button"));
				} else {
					$(element).parent().addClass("error");
					label.insertAfter(element);
				}
			},
			success: function (label, element) {
				$(element).parent().removeClass("error");
				label.remove();
			},
			rules: {
				"email": {
					email: true
				},
				"services1": servicesRule,
				"services2": servicesRule,
				"services3": servicesRule,
				"services4": servicesRule,
				"media1": mediaRule,
				"media2": mediaRule,
				"media3": mediaRule,
				"media4": mediaRule,
				"media5": mediaRule,
				"media6": mediaRule,
				"media7": mediaRule
			},
			messages: {
				"name-first": "Oops! You skipped me",
				"name-last": "Oops! You skipped me",
				"company-name": "Oops! You skipped me.",
				"email": "This is awkward. That email's invalid.",
				"regarding": "Oops, you skipped me! Please select one.",
				"messageServices": "Oops! You skipped me.",
				"messageMedia": "Oops! You skipped me.",
				"messageGeneral": "Oops! You skipped me.",
				"services1": "Oops, you skipped me! Please select one.",
				"media1": "Oops, you skipped me! Please select one.",
			},
			submitHandler: function (form){
				var postData = $(form).serializeArray();
				postData.push({name: 'hostname', value: document.location.hostname});

				// Google reCAPTCHA
				grecaptcha.ready(function(){
					grecaptcha.execute('6LfFWSIaAAAAABkwtFshM6P4TnY3rVFlkQoJ94ky', {action: 'jotformSubmit'}).then(function (token) {
						postData.push({name: 'token', value: token});
						postData.push({name: 'action', value: 'jotformSubmit'});
						//console.log(postData);
						$.ajax({
							type: "POST",
							url: "../jotform-contact-us.php",
							data: postData,
							cache: false
						}).done(function (data) {
							//console.log("ajax returned: "+data);
							$(".rain-contact input[type=checkbox]").prop( "checked", false );
							$(".rain-contact select").prop("selectedIndex", 0);
							$(".rain-contact input[type=text], .rain-contact textarea").val("");
							showDesiredStep();
							pushGAevent('55PlusLP','Form_Submit','Contact Us');
						}).fail(function (xhr, textStatus, errorThrown) {
							//console.log("ajax failure: " + textStatus + "|" + errorThrown);
						});
					});
				});
			}
		});

		if (_form.valid() == true) {
			//on ajax success?
			if (_nextStep === "6") {
				//makes the form submit so we can get into submithandler
				_form.submit();
			} else {
				showDesiredStep();
			}
		}
	}

	function showDesiredStep() {
		//Removes active class from pagination
		$(".rain-contact-footer-pagination-bullet").removeClass("active");

		//Hides current step
		$(_currentStep).fadeOut(function () {
			//Shows current step
			$(".rain-contact-step-" + _nextStep).fadeIn();
		});

		//Scroll to top contact module
		$root.animate({
			scrollTop: $(".rain-contact").offset().top - 64
		}, 300);
	}

	parallaxCopy();

});

// ===================================================================
// Global Function to push GA events to GTM dataLayer
// ===================================================================
function pushGAevent(category,action,label){
	if (typeof window.dataLayer !== 'undefined'){
		window.dataLayer.push({
			'event': 'Google Analytics - Event',
			'GAEvent_category': '55PlusLP',
			'GAEvent_action': action,
			'GAEvent_label': label
		});
	}
}